// import PropTypes from "prop-types";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Header from "./header";
import Footer from "./footer";
import "../css/style.css";

const duration = 0.5;

const variants = {
	initial: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: duration,
			delay: duration,
			when: "beforeChildren",
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: duration },
	},
};

const Layout = ({ children, location }) => (
	<>
		<Header></Header>
		<div>
			<AnimatePresence>
				<motion.main
					variants={variants}
					initial="initial"
					animate="enter"
					exit="exit"
				>
					{children}
				</motion.main>
			</AnimatePresence>
		</div>
		<Footer></Footer>
	</>
);

/*
Layout.propTypes = {
	children: PropTypes.node.isRequired,
};
*/

export default Layout;
