import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";

function Header() {
	const [isExpanded, toggleExpansion] = useState(false);
	const { site } = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<header className="bg-white">
			<div className="mx-auto flex flex-wrap items-center justify-between p-4 lg:px-8">
				<motion.button whileHover={{ scale: 1.04 }} whileTap={{ scale: 0.9 }}>
					<Link to="/">
						<h1 className="flex items-center text-gray-800 no-underline">
							{/*<svg
							className="w-8 h-8 mr-2 fill-current"
							height="54"
							viewBox="0 0 54 54"
							width="54"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
						</svg>
						<span className="text-xl font-bold tracking-tight">
							{site.siteMetadata.title}
						</span>
						*/}
							<img
								style={{ maxHeight: "40px" }}
								src="/assets/LP-TREE-logo.png"
								alt="tree service janesville wisconsin"
							/>
						</h1>
					</Link>
				</motion.button>

				<button
					className="flex items-center block px-3 py-2 text-gray-800 border border-white rounded md:hidden"
					onClick={() => toggleExpansion(!isExpanded)}
				>
					<svg
						className="w-3 h-3 fill-current"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Menu</title>
						<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
					</svg>
				</button>

				<nav
					className={`${
						isExpanded ? `block` : `hidden`
					} md:block md:flex md:items-center w-full md:w-auto`}
				>
					{[
						{
							route: `/about`,
							title: `About`,
						},
						{
							route: `/blog`,
							title: `Blog`,
						},
						{
							route: `/faq`,
							title: `FAQ`,
						},
						{
							route: `/contact`,
							title: `Contact`,
						},
					].map((link) => (
						<Link
							className="block mt-4 text-gray-800 no-underline md:inline-block md:mt-0 md:ml-6"
							key={link.title}
							to={link.route}
						>
							{link.title}
						</Link>
					))}

					<Link
						className="block mt-4 text-gray-800 no-underline md:inline-block md:mt-0 md:ml-6"
						to="https://www.facebook.com/lptreeservice/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<AiFillFacebook />
					</Link>
					<Link
						className="block mt-4 text-gray-800 no-underline md:inline-block md:mt-0 md:ml-6"
						to="https://www.instagram.com/lptreeservice/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<AiFillInstagram />
					</Link>
				</nav>
			</div>
		</header>
	);
}

export default Header;
