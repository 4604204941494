//import { Link } from "gatsby";
import React from "react";
import { graphql, StaticQuery, Link } from "gatsby";

function Footer() {
	/*
	let footerposts = data.allMarkdownRemark.edges.map(function (item, i) {
		return <li key={i}>Test</li>;
	});
	*/

	return (
		<StaticQuery
			query={graphql`
				query HeadingQuery {
					allMarkdownRemark(
						filter: { frontmatter: { type: { eq: "post" } } }
						sort: { order: ASC, fields: frontmatter___date }
						limit: 6
					) {
						edges {
							node {
								id
								frontmatter {
									date(fromNow: true)
									title
									slug
								}
								fields {
									slug
								}
							}
						}
					}
				}
			`}
			render={(data) => (
				<footer>
					<div className="bg-gray-800">
						<div className="mx-auto py-8 px-4 sm:px-6 lg:py-8 lg:px-8">
							<div className="xl:grid xl:grid-cols-1 xl:gap-8">
								<div className="grid grid-cols-2 gap-8 xl:col-span-2">
									<div>
											<h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
												Contact
											</h4>
											<ul className="mt-4">
												<li className="mt-4">
													<p className="text-base leading-6 text-gray-300">
														Hours: M-F: 8AM to 5PM
													</p>
												</li>
												<li className="mt-4">
													<p className="text-base leading-6 text-gray-300">
														Hours: 24 Hour Emergency Service
													</p>
												</li>
												<li className="mt-4">
													<a
														href="/#"
														className="text-base leading-6 text-gray-300 hover:text-white"
													>
														Office: (608)754-2312
													</a>
												</li>
												<li className="mt-4">
													<a
														href="/#"
														className="text-base leading-6 text-gray-300 hover:text-white"
													>
														Emergency: (608)290-6463
													</a>
												</li>
												<li className="mt-4">
													<a
														href="/#"
														className="text-base leading-6 text-gray-300 hover:text-white"
													>
														1134 N Touson Dr <br />
														Janesville, WI 53546
													</a>
												</li>
											</ul>
										</div>
									<div className="md:grid md:grid-cols-2 md:gap-8 lg:grid-cols-2 lg:gap-8">
									<div className="">
											<h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
												Links
											</h4>
											<ul className="mt-4">
												<li>
													<Link
														className="text-base leading-6 text-gray-300 hover:text-white"
														to="/"
													>
														Home
													</Link>
												</li>
												<li className="mt-4">
													<Link
														className="text-base leading-6 text-gray-300 hover:text-white"
														to="/about"
													>
														About
													</Link>
												</li>
												<li className="mt-4">
													<Link
														className="text-base leading-6 text-gray-300 hover:text-white"
														to="/blog"
													>
														Blog
													</Link>
												</li>
												<li className="mt-4">
													<Link
														className="text-base leading-6 text-gray-300 hover:text-white"
														to="/faq"
													>
														FAQ
													</Link>
												</li>
												<li className="mt-4">
													<Link
														className="text-base leading-6 text-gray-300 hover:text-white"
														to="/contact"
													>
														Contact
													</Link>
												</li>
											</ul>
										</div>
										<div className="mt-12 md:mt-0">
											<div>
												<h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
													Posts
												</h4>

												<ul className="mt-4">
													{data.allMarkdownRemark.edges
														.slice(0, 4)
														.map((link, i) => (
															<li key={i} className="mb-4">
																<Link
																	to={`${link.node.fields.slug}`}
																	className="text-base leading-6 text-gray-300 hover:text-white"
																>
																	{link.node.frontmatter.title}
																</Link>
															</li>
														))}
												</ul>
											</div>
										</div>
										
									</div>

									{/*
									<div className="md:grid md:grid-cols-1 md:gap-8 lg:grid-cols-1 lg:gap-8">
										<h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-500 uppercase">
											Links
										</h4>
										<ul className="mt-4">
											<li>
												<Link
													className="text-base leading-6 text-gray-300 hover:text-white"
													to="/"
												>
													Home
												</Link>
											</li>
											<li className="mt-4">
												<Link
													className="text-base leading-6 text-gray-300 hover:text-white"
													to="/about"
												>
													About
												</Link>
											</li>
											<li className="mt-4">
												<Link
													className="text-base leading-6 text-gray-300 hover:text-white"
													to="/blog"
												>
													Blog
												</Link>
											</li>
											<li className="mt-4">
												<Link
													className="text-base leading-6 text-gray-300 hover:text-white"
													to="/faq"
												>
													FAQ
												</Link>
											</li>
											<li className="mt-4">
												<Link
													className="text-base leading-6 text-gray-300 hover:text-white"
													to="/contact"
												>
													Contact
												</Link>
											</li>
										</ul>
									</div>
									*/}
								</div>
							</div>
							<hr className="mt-8" style={{ borderColor: "#596371" }} />
							<div
								className="md:flex sm:items-center sm:justify-between"
								style={{ justifyContent: "center" }}
							>
								<p
									className="text-base leading-6 text-gray-400 mt-8 md:order-1"
									style={{ textAlign: "center" }}
								>
									© {new Date().getFullYear()}, LP Tree Service
									{` `}All rights reserved.
								</p>
							</div>
							<div
								className="md:flex sm:items-center sm:justify-between"
								style={{ justifyContent: "center" }}
							>
								<p
							className="white"
							style={{
								textAlign: "center",
								color: "#909090",
								fontSize: ".9em",
							}}
						>
							Designed and Developed by{" "}
							<a
								href="https://kloud9creative.com"
								target="_blank"
								rel="noopener noreferrer"
								className="k9 text-gray-400"
								
							>
								Kloud 9 Creative
							</a>
						</p>
							</div>
						</div>
					</div>
				</footer>
			)}
		/>
	);
}

export default Footer;
